import React, { useState } from "react";
import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import CloudScapeHeader from "@cloudscape-design/components/header";
import CloudScapeSpaceBetween from "@cloudscape-design/components/space-between";
import { RedButton, OrangeButton } from "../Buttons/CustomizedButtons";
import { DeleteModal } from "../Modal/DeleteModal";
import { FORM_MODE, PAGE_TITLE } from "../../constants/constants";
import { PromptCutTicketType } from "../../utils/ModalUtils";
import { getTPSSecurityTeamId } from "../../utils/TPSConfigUtils";
import { decodeString } from "src/utils/CommonUtils";

export interface SecurityTeamConfigPageHeaderProps extends WithBundleProps {
  formMode: FORM_MODE; //formMode in which this component will be used
  submitForm: () => void; //Function to submit form
  formSubmissionStatus: boolean;
  promptCutTicket: PromptCutTicketType;
  editSecurityTeamName?: string; //Component will receive security team name in edit mode to display as page header text
  securityTeamId?: string; //Security record Id to delete in edit mode
  disableFormActionButtons?: boolean; //Disable submit button in edit mode when API fetch failed
}

export const SecurityTeamConfigPageHeader = withBundle(
  "components.SecurityTeamConfigPageHeader"
)((props: SecurityTeamConfigPageHeaderProps) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  return (
    <React.Fragment>
      <DeleteModal
        selectedRecordIds={props.securityTeamId ? [props.securityTeamId] : []}
        deleteModalOpen={deleteModalOpen}
        closeModal={() => setDeleteModalOpen(false)}
        page={PAGE_TITLE.SECURITY_TEAM_CONFIG}
        promptCutTicket={props.promptCutTicket}
      />
      <CloudScapeHeader
        variant="awsui-h1-sticky"
        actions={
          <CloudScapeSpaceBetween size="xs" direction="horizontal">
            {props.formMode === FORM_MODE.EDIT &&
              props.securityTeamId !== getTPSSecurityTeamId("beta") && ( //User can see Delete button on ConFig Page in Edit Mode when Security Team Record is non TPS
                <RedButton
                  formAction="none" //This button is part of Security Team Config Page's with no form action but needed to delete this record
                  onClick={() => setDeleteModalOpen(true)}
                  disabled={props.disableFormActionButtons} //Disable Delete button in Edit Mode when API fetch failed
                >
                  {props.bundle.getMessage(
                    "security_team_config_page_delete_button_text"
                  )}
                </RedButton>
              )}
            <OrangeButton
              variant="primary"
              formAction="submit" //This button is part of Security Team Config Page's form with Submit action
              disabled={props.disableFormActionButtons} //Disable Submit button in Edit Mode when API fetch failed
              onClick={props.submitForm}
              loading={props.formSubmissionStatus} //Display loading spinner when Mutation is in progress
            >
              {props.bundle.getMessage(
                `security_team_config_page_submit_button_text_in_${props.formMode}_mode`
              )}
            </OrangeButton>
          </CloudScapeSpaceBetween>
        }
      >
        {props.formMode === FORM_MODE.EDIT
          ? decodeString(props.editSecurityTeamName!)
          : props.bundle.getMessage(
              "security_team_config_page_create_header_text"
            )}
      </CloudScapeHeader>
    </React.Fragment>
  );
});
