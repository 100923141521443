import { MessageBundle } from "@amzn/arb-tools";
import { PAGE_TITLE, Stage } from "../constants/constants";
import {
  SERVICE_NOW_IRM_DEV,
  SERVICE_NOW_IRM_UAT,
  SERVICE_NOW_IRM_PROD,
} from "../constants/urlConstants";

//todo add text specific to Security Create or Edit Configuration Page
export const getBreadCrumbText = (
  path: string,
  bundle: MessageBundle,
  breadCrumbDisplayValue: string
): string => {
  if (path.includes(PAGE_TITLE.TASKS))
    return bundle.getMessage(PAGE_TITLE.TASKS);
  if (path.includes(PAGE_TITLE.TAGS)) return bundle.getMessage(PAGE_TITLE.TAGS);
  if (
    path.includes(PAGE_TITLE.SECURITY_TEAMS) ||
    path.includes("security-teams")
  )
    return bundle.getMessage(PAGE_TITLE.SECURITY_TEAMS);
  if (
    path.includes(PAGE_TITLE.SECURITY_TEAM_CONFIG) ||
    path.includes("security-team-config")
  ) {
    if (breadCrumbDisplayValue !== "") {
      return breadCrumbDisplayValue;
    } else {
      return bundle.getMessage(PAGE_TITLE.SECURITY_TEAM_CONFIG);
    }
  }
  if (path === "") return bundle.getMessage(PAGE_TITLE.HOME);
  return path;
};

export const getServiceNowHomeURL = (stage: string): string => {
  switch (stage) {
    case Stage.BETA:
      return SERVICE_NOW_IRM_DEV;
    case Stage.GAMMA:
      return SERVICE_NOW_IRM_UAT;
    case Stage.PROD:
    default:
      return SERVICE_NOW_IRM_PROD;
  }
};

export const getBreadCrumbHref = (
  pathIndex: number,
  childPaths: string[]
): string => {
  if (pathIndex === 0) return getServiceNowHomeURL("beta"); //this beta value will be replaced by deployment time runtime stage value either beta, gamma, prod as configured here in CDK - https://tiny.amazon.com/179wl8foj/codeamazpackTPSSbloba254lib

  return childPaths
    .slice(0, pathIndex < childPaths.length ? pathIndex + 1 : pathIndex)
    .join("/");
};
